<template>
  <v-container>
    <v-btn :to="{name: backRouteName}" color="blue" dark class="mt-3 ml-3" v-if="!loading">
      <v-icon class="mr-1">mdi-arrow-left-circle</v-icon>
      {{ backRouteName }}
    </v-btn>
    <v-progress-circular indeterminate color="orange" v-show="loading" size="60" class="ma-auto"/>
    <v-alert color="red" type="error" v-if="error" class="mt-10">Aucun intervenant trouvé</v-alert>
    <v-row class="mt-10" no-gutters v-if="intervenant">
      <h2>En savoir plus sur :</h2>
    </v-row>
    <v-row align-content="center" justify="center" v-if="intervenant">
      <v-col cols="12" class="text-center">
        <v-row>
          <v-card class="ma-auto" width="90%" max-width="400px">
            <v-card-title class="card-title" v-text="intervenant.nom"></v-card-title>
            <v-img :src="getFullURL(intervenant.mediaObjectImage)" height="200px" contain></v-img>
            <v-card-text v-html="intervenant.descriptionComplet"/>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon v-if="intervenant.siteWeb" target="_blank" :href="intervenant.siteWeb">
                <v-icon>mdi-link-variant</v-icon>
              </v-btn>
              <v-btn icon v-if="intervenant.facebook" target="_blank" :href="intervenant.facebook">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon v-if="intervenant.twitter" target="_blank" :href="intervenant.twitter">
                <v-icon>mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon v-if="intervenant.youtube" target="_blank" :href="intervenant.youtube">
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MediaObjectMixin from "../../../mixins/MediaObjectMixin";
import IntervenantService from "../../../services/intervenant.service";

export default {
  name: "Single-intervenant",
  mixins: [MediaObjectMixin],
  data: () => ({
    intervenant: null,
    error: false,
    loading: false
  }),
  created() {
    if (this.currentIntervenantIdToLoad) {
      this.loading=true;
      IntervenantService.getIntervervant(this.currentIntervenantIdToLoad).then(intervenant => {
        this.intervenant = intervenant['data'];
      }).catch((e) => {
        this.error = true;
        console.error(e)
      }).finally(() => {
        this.loading = false;
      })
    }
  },
  computed: {
    currentIntervenantIdToLoad() {
      return this.$route.params.intervenantId ? this.$route.params.intervenantId : null;
    },
    backRouteName() {
      let routeName = 'Home'
      if (this.intervenant != null) {
        switch (this.intervenant.intervenantType.slug) {
          case 'exposants': {
            routeName = 'Exposants';
          }
            ;
            break;
          case 'invite': {
            routeName = 'Invités';
          }
            ;
            break;
        }
      }
      return routeName;
    }
  },

}
</script>

<style scoped lang="scss">
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  .v-card__title {
    font-family: $font-titre;
    font-weight: bold;
    justify-content: center;
    color: ghostwhite;
    background-color: $bleu;
  }

  .v-card__text {
    font-family: $font;
    background-color: #eeeeee;

    span {
      font-weight: bold;
    }
  }
}

</style>